import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import { NamedLink } from '../../components';
import EditIconDark from './EditIconDark';

import css from './ListingPage.module.css';

const SectionFormOfSupportMaybe = props => {
  const { options, publicData, listingId, listingSlug, listingType, isOwnListing, canEditListing, isExample } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions = publicData && publicData.categories ? publicData.categories : [];

  if(selectedOptions.length>0){
    const editLink = !isExample && isOwnListing && canEditListing ? (<NamedLink className={css.editListingLinkDark} name='EditLeadPage' params={{
        id: listingId.uuid,
        slug: listingSlug,
        type: listingType,
        tab: "support-categories",
        }}>
        <EditIconDark className={css.editIcon} />
      </NamedLink>
    ) : null;
    return (
        <div className={css.sectionFeatures}>
          <h2 className={css.featuresTitle}>
            <FormattedMessage id="ListingPage.formOfSupportTitle" />
            {editLink}
          </h2>
          <PropertyGroup
            id="ListingPage.formOfSupport"
            options={options}
            selectedOptions={selectedOptions}
            twoColumns={false}
          />
        </div>
    );
  }
  return null;
};

export default SectionFormOfSupportMaybe;
