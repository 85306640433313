import React from 'react';
import { injectIntl } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { NamedLink } from '../../components';
import EditIconDark from './EditIconDark';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description, listingId, listingSlug, listingType, isOwnListing, canEditListing, isLead, intl, isExample } = props;
  const editLinkName = isLead ? 'EditLeadPage' : 'EditListingPage';
  const tabName = isLead ? 'lead-description' : 'description';
  const editLink = !isExample && isOwnListing && canEditListing ? (<NamedLink className={css.editListingLinkDark} name={editLinkName} params={{
    id: listingId.uuid,
    slug: listingSlug,
    type: listingType,
    tab: tabName,
  }}>
  <EditIconDark className={css.editIcon} />
  </NamedLink>) : null ;
  const descriptionTitleId = description && isLead ? 'ListingPage.descriptionTitleLead' : 'ListingPage.descriptionTitle';
  const descriptionTitleString = intl.formatMessage({ id: descriptionTitleId });
  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        {descriptionTitleString}
        {editLink}
      </h2>
      <p className={css.description}>
        {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
