import _ from 'lodash';

import moment from 'moment-timezone';
import { array, arrayOf, bool, func, oneOf, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  BookingPanel,
  Footer,
  GenericMessage,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  NamedLink,
  NamedRedirect,
  Page,
  PrivacyPolicy,
  TermsOfService,
  TermsOfServiceLead,
} from '../../components';
import config from '../../config';
import { NotFoundPage, TopbarContainer } from '../../containers';
import { canEditListing as canUserEditListing } from '../../containers/EditListingPage/EditListingPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { EditApplicationDeadlineForm, EnquiryForm } from '../../forms';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_TYPES } from '../../util/constants';
import { formatMoney } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_CLOSED, LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import {
  createSlug,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { BronzeErrorPage } from './BronzeErrorPage';

import {
  editTender,
  fetchPostalCode,
  fetchQuestions,
  fetchTransactionLineItems,
  sendAnswer,
  sendEnquiry,
  sendProposal,
  sendQuestion,
  setInitialValues,
} from './ListingPage.duck';

import css from './ListingPage.module.css';
import SectionClientMaybe from './SectionClientMaybe';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionDialuxMaybe from './SectionDialuxMaybe';
import SectionDocumentationMaybe from './SectionDocumentationMaybe';
import SectionFormOfSupportMaybe from './SectionFormOfSupportMaybe';
import SectionHeading from './SectionHeading';
import SectionImages from './SectionImages';
import SectionIndoorMaybe from './SectionIndoorMaybe';
import SectionLanguageMaybe from './SectionLanguageMaybe';
import SectionLightingDesignMaybe from './SectionLightingDesignMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionOthersMaybe from './SectionOthersMaybe';
import SectionOutdoorMaybe from './SectionOutdoorMaybe';
import SectionPhotoWorkMaybe from './SectionPhotoWorkMaybe';
import SectionProjectDetailsMaybe from './SectionProjectDetailsMaybe';
import SectionQ_A from './SectionQ_A';
import SectionReviews from './SectionReviews';
import SectionWayOfSupportMaybe from './SectionWayOfSupportMaybe';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const ACCEPT_PDF = 'pdf/*';
const MAX_DOCUMENTS = 2;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find((c) => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;

    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      questionModalOpen: false,
      answerModalOpen: false,
      answerTo: null,
      allQuestions: false,
      showNotExpertMessage: false,
      tosModalOpen: false,
      dseModalOpen: false,
      leadModalOpen: false,
      editTenderModalOpen: false,
      showAvatar: false,
      isExample: false,
      documents: [],
      uploadInProgress: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.handleSendQuestion = this.handleSendQuestion.bind(this);
    this.handleSendAnswer = this.handleSendAnswer.bind(this);
    this.handleSeeAllQuestions = this.handleSeeAllQuestions.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onEditTender = this.onEditTender.bind(this);
    this.questionsRef = React.createRef();
    this.onFileUploadHandler = this.onFileUploadHandler.bind(this);
    this.onFileRemove = this.onFileRemove.bind(this);
  }

  componentDidMount() {
    const shouldBeContacted = this.props.shouldBeContacted;
    if (shouldBeContacted) {
      this.onContactUser();
    }

    const isExample = this.props.isExample;
    if (isExample) {
      this.setState({
        isExample: true,
      });
    }
    const isLead = this.props.isLead;
    if (!isLead) {
      this.setState({
        showAvatar: true,
      });
    }
    this.props.onSeeAllQuestions(this.props.params.id, false);
    this.props.onCanEditListing(this.props.params.id);
    if (this.props.location?.state?.scrollToQuestions) {
      this.scrollToQuestions();
    }

    this.props.onFetchPostalCode(this.props.params.id);
  }

  onFileUploadHandler(e, form, file, field) {
    if (this.state[field].length >= MAX_DOCUMENTS) {
      return false;
    }
    const url = 'https://api.cloudinary.com/v1_1/lightshift/upload';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'vbh822gi');
    formData.append('resource_type', 'pdf');
    this.setState({
      uploadInProgress: true,
    });
    fetch(url, { method: 'POST', body: formData })
      .then((response) => response.text())
      .then((data) => {
        const res = JSON.parse(data);
        if (res && res.secure_url) {
          form.change(field, [...this.state[field], res]);
          this.setState({
            [field]: [...this.state[field], res],
            uploadInProgress: false,
          });
          e.target.value = null;
        }
      });
  }

  onFileRemove = (id, documentId) => (e) => {
    e.stopPropagation();
    const stateValues = {
      [id]: this.state[id].filter((document) => document.public_id !== documentId),
    };
    this.setState(stateValues);
  };

  scrollToQuestions = () => {
    setTimeout(() => {
      this.questionsRef.scrollIntoView({
        behavior: 'smooth',
      });
    }, 500);
  };

  setQuestionsRef = (ref) => {
    this.questionsRef = ref;
  };

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      currentUser,
      onSendProposal,
      intl,
    } = this.props;

    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const metadata = listing?.attributes?.metadata;
    const isExample = !!metadata?.isExample;
    const isLead = listing?.attributes?.publicData?.listingCategory === 'lead';
    const hasExpertLisitngId = !!(
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile.privateData.listingID
    );
    const expertLisitngId =
      currentUser && currentUser.attributes && currentUser.attributes.profile.privateData.listingID;
    const isManufacturer =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile.metadata.isManufacturer
        ? currentUser.attributes.profile.metadata.isManufacturer
        : false;
    if (!isExample && !hasExpertLisitngId && !isManufacturer) {
      this.setState({
        showNotExpertMessage: true,
      });
      return;
    }

    const { bookingDates, offerPrice, service, documents } = values;
    const {
      ownTcAndPp,
      ownTermsAndConditions,
      ownPrivacyPolicy,
      termsAndConditions,
      privacyPolicies,
    } = currentUser.attributes.profile?.privateData;
    // Latest terms and conditions and privacy policy of expert
    const privacyPolicy =
      ownTcAndPp &&
      ownPrivacyPolicy &&
      privacyPolicies?.length > 0 &&
      _.orderBy(
        privacyPolicies,
        [
          function (object) {
            return new Date(object.created_at);
          },
        ],
        ['desc']
      )[0];
    const terms =
      ownTcAndPp &&
      ownTermsAndConditions &&
      termsAndConditions?.length > 0 &&
      _.orderBy(
        termsAndConditions,
        [
          function (object) {
            return new Date(object.created_at);
          },
        ],
        ['desc']
      )[0];

    const termsAndPp = {
      privacyPolicy: privacyPolicy?.secure_url,
      termsAndConditions: terms?.secure_url,
    };

    const initialValues = isLead
      ? {
          listing: listing,
          offerData: {
            authorId: listing.author.id.uuid,
            expertId: currentUser.id.uuid,
            service,
            documents,
            ...termsAndPp,
            isLead,
          },
        }
      : {
          listingId: listing.id.uuid,
          authorId: listing.author.id.uuid,
          expertId: currentUser.id.uuid,
          expertLisitngId: expertLisitngId,
          deliveryDate: bookingDates.date,
          amount: offerPrice.amount,
          currency: offerPrice.currency,
          service,
          ...termsAndPp,
          isLead,
        };

    if (isLead) {
      const saveToSessionStorage = !this.props.currentUser;

      const routes = routeConfiguration();
      const { setInitialValues } = findRouteByRouteName('LeadCheckoutPage', routes);
      callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

      // Clear previous Stripe errors from store if there is any
      onInitializeCardPaymentData();
      // Redirect to CheckoutPage
      history.push(
        createResourceLocatorString('LeadCheckoutPage', routes, {
          lang: intl.locale,
          id: listing.id.uuid,
          slug: createSlug(listing.attributes.title),
        })
      );
    } else {
      onSendProposal(initialValues);
    }
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location, intl } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(
        createResourceLocatorString('SignupPage', routeConfiguration(), { lang: intl.locale }),
        state
      );
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, intl } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message, documents } = values;
    const metadata = {
      documents,
    };
    onSendEnquiry(listingId, message.trim(), metadata)
      .then((txId) => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, {
            lang: intl.locale,
            id: txId.uuid,
          })
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  handleSendQuestion(values) {
    const { params, onSendQuestion, currentUser, getListing } = this.props;
    const listingId = new UUID(params.id);
    const currentListing = ensureListing(getListing(listingId));

    const authorAvailable = currentListing && currentListing.author;
    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    if (!(currentListing && currentUser && ensuredAuthor)) {
      return;
    }
    const { question } = values;
    const all = this.state.allQuestions.toString();

    onSendQuestion({
      listingId: currentListing.id.uuid,
      userId: currentUser.id.uuid,
      authorId: ensuredAuthor.id.uuid,
      question: question.trim(),
      all: all,
    })
      .then((res) => {
        this.setState({ questionModalOpen: false });
      })
      .catch(() => {
        this.setState({ questionModalOpen: true });
      });
  }

  handleSendAnswer(values, id) {
    const { params, onSendAnswer, currentUser, getListing } = this.props;
    const listingId = new UUID(params.id);
    const currentListing = ensureListing(getListing(listingId));

    if (!(currentListing && currentUser)) {
      return;
    }
    const { answer } = values;
    const answerTo = this.state.answerTo;
    const answerToId = answerTo && answerTo.id;
    const all = this.state.allQuestions.toString();

    onSendAnswer({
      id: answerToId,
      listingId: currentListing.id.uuid,
      authorId: currentUser.id.uuid,
      answer: answer.trim(),
      all: all,
    })
      .then((res) => {
        this.setState({ answerModalOpen: false });
      })
      .catch(() => {
        this.setState({ answerModalOpen: true });
      });
  }

  handleSeeAllQuestions(seeAllQuestions = false) {
    const { params, onSeeAllQuestions, currentUser, getListing } = this.props;
    const listingId = new UUID(params.id);
    const currentListing = ensureListing(getListing(listingId));
    if (!(currentListing && currentUser)) {
      return;
    }
    onSeeAllQuestions(params.id, seeAllQuestions)
      .then((res) => {
        this.setState({ allQuestions: seeAllQuestions });
      })
      .catch(() => {});
  }

  onEditTender(values) {
    const { params, currentUser, getListing } = this.props;
    const listingId = new UUID(params.id);
    const currentListing = ensureListing(getListing(listingId));
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    if (!isOwnListing) {
      console.log('not ownListing, leave function');
      return;
    }
    const { applicationDeadlineChanged, listingEndDate, jobTypeGroup, isLead } = values;
    if (!isLead) {
      const listingEndDateUtc = moment(listingEndDate.date).endOf('day').utc();
      const listingEndDateFormatted = listingEndDateUtc.format();
      const listingEndDateUnix = listingEndDateUtc.unix();
      const updateValues = {
        id: params.id,
        publicData: {
          listingEndDateUTC: listingEndDateFormatted,
          listingEndDateUNIX: listingEndDateUnix,
          jobTypeGroup,
        },
      };
      this.props
        .editTender(updateValues)
        .then((res) => {
          this.setState({ editTenderModalOpen: false });
        })
        .catch(() => {
          this.setState({ editTenderModalOpen: true });
        });
      return;
    }
    const applicationDeadline = moment(applicationDeadlineChanged.date).endOf('day').utc().format();
    const applicationDeadlineUNIX = moment(applicationDeadlineChanged.date)
      .endOf('day')
      .utc()
      .unix();
    const updateValues = {
      id: params.id,
      publicData: { applicationDeadline, applicationDeadlineUNIX },
    };

    this.props
      .editTender(updateValues)
      .then((res) => {
        this.setState({ editTenderModalOpen: false });
      })
      .catch(() => {
        this.setState({ editTenderModalOpen: true });
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      sendProposalInProgress,
      sendProposalError,
      sendProposalMessage,
      sendQuestionInProgress,
      sendQuestionError,
      sendAnswerInProgress,
      sendAnswerError,
      questions,
      totalQuestionsCount,
      users,
      canEditListing,
      updateListingInProgress,
      updateListingError,
      listingUpdated,
      redirectToListing,
      totalListingsCount,
      postalCode,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const listingCategory = currentListing?.attributes?.publicData?.listingCategory;
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant
      ? 'photos'
      : listingCategory === LISTING_TYPES.LEAD
      ? 'application'
      : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
      metadata,
      createdAt,
    } = currentListing.attributes;

    if (!publicData?.listingCategory) {
      // lets wait another second
      return null;
    }

    const isLead = publicData?.listingCategory === 'lead';
    const isExample = !!metadata?.isExample;
    const isManufacturer = currentUser && !!currentUser.attributes.profile.metadata.isManufacturer;

    const subscription = currentUser?.attributes?.profile?.metadata?.subscription ?? 'bronze';
    const isPayedSubscription = subscription === 'gold' || subscription === 'silver';

    let isAbleToSeeListing = isOwnListing;
    isAbleToSeeListing |= isExample;
    isAbleToSeeListing |= isLead && isManufacturer;
    isAbleToSeeListing |= isPayedSubscription;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    if (!isAbleToSeeListing) {
      return <BronzeErrorPage intl={intl} scrollingDisabled={scrollingDisabled} />;
    }

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = isLead ? (
      <>
        <span className={css.bookingTitle}>
          <FormattedMessage id="ListingPage.supportTo" />
        </span>
        <br />
        <span className={css.leadTitle}>
          <FormattedMessage id="ListingPage.leadTitle" values={{ title: richTitle }} />
        </span>
      </>
    ) : (
      <>
        <span className={css.bookingTitle}>
          <FormattedMessage id="ListingPage.offerTo" />
        </span>
        <br />
        <span className={css.leadTitle}>
          <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
        </span>
      </>
    );

    const bookingSubTitle = isLead
      ? intl.formatMessage({ id: 'ListingPage.leadSubTitle' })
      : isExample
      ? null
      : intl.formatMessage({ id: 'ListingPage.bookingSubTitle' });

    const breakdownTitle = <FormattedMessage id="ListingPage.breakdownTitle" />;

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = (e) => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const showContactUser = !isLead && authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const clientListingsCount = totalListingsCount.data
      ? totalListingsCount.data.meta.totalItems
      : null;

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;

    const handleBookingSubmit = (values) => {
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map((image) => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find((i) => i.name === variantName) : null;

          return variant || size;
        })
        .filter((variant) => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map((img) => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const dialuxOptions = findOptionsForSelectFilter('dialux', filterConfig);
    const lightingDesignOptions = findOptionsForSelectFilter('lightingDesign', filterConfig);
    const photoWorkOptions = findOptionsForSelectFilter('photoWork', filterConfig);
    const documentationOptions = findOptionsForSelectFilter('documentation', filterConfig);
    const othersOptions = findOptionsForSelectFilter('others', filterConfig);
    const languageOptions = findOptionsForSelectFilter('language', filterConfig);
    const indoorOptions = findOptionsForSelectFilter('indoor', filterConfig);
    const outdoorOptions = findOptionsForSelectFilter('outdoor', filterConfig);
    const formOfSupportOptions = findOptionsForSelectFilter('categories', filterConfig);
    const wayOfSupportOptions = findOptionsForSelectFilter('support', filterConfig);

    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoryOptions, publicData.category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;
    const isFuzzy = config.maps.fuzzy.enabled && isLead ? true : false;
    const applicationEndDateUTC =
      publicData && publicData.applicationDeadline ? publicData.applicationDeadline : null;
    const listingEndDateUTC =
      publicData && publicData.listingEndDateUTC ? publicData.listingEndDateUTC : null;
    const realisationEndDateUTC =
      publicData && publicData.realisationEndDate ? publicData.realisationEndDate : null;

    const format = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    };

    const LTZ = moment.tz.guess();
    const listingEndDateLTZ = listingEndDateUTC && moment.tz(listingEndDateUTC, LTZ).format();
    const applicationEndDateLTZ =
      applicationEndDateUTC && moment.tz(applicationEndDateUTC, LTZ).format();
    const realisationEndDateLTZ =
      realisationEndDateUTC && moment.tz(realisationEndDateUTC, LTZ).format();
    const jobTypeGroup = (publicData && publicData.jobTypeGroup) || 'public';
    const listingEndDate =
      listingEndDateUTC && moment.tz(listingEndDateUTC, moment.tz.guess()).toDate();
    const applicationDeadlineChanged =
      applicationEndDateUTC && moment.tz(applicationEndDateUTC, moment.tz.guess()).toDate();

    const createdAtLTZ = moment.tz(createdAt, LTZ).format();

    const formattedListingEndDate = listingEndDateUTC && intl.formatDate(listingEndDateLTZ, format);
    const formattedApplicationDeadline =
      applicationEndDateUTC && intl.formatDate(applicationEndDateLTZ, format);

    const formattedRealisationEndDate =
      realisationEndDateUTC && intl.formatDate(realisationEndDateLTZ, format);
    const formattedCreatedAt = intl.formatDate(createdAtLTZ, format);
    const applicationDeadlineId = !isLead
      ? 'ListingPage.applicationDeadline'
      : 'ListingPage.applicationDeadlineLead';
    const skillTooltipId = !isLead ? 'ListingPage.skillTooltip' : 'ListingPage.skillTooltipLead';
    const skillTooltipIdString = formattedApplicationDeadline
      ? intl.formatMessage({ id: skillTooltipId })
      : null;
    const applicationDeadlineString = isLead
      ? intl.formatMessage(
          { id: applicationDeadlineId },
          {
            dates: `${formattedApplicationDeadline}`,
          }
        )
      : null;

    const listingEndDateString = !isLead
      ? intl.formatMessage(
          { id: applicationDeadlineId },
          {
            dates: `${formattedListingEndDate}`,
          }
        )
      : null;

    const expiredString = intl.formatMessage({ id: 'ListingPage.expiredMessage' });

    const deadlineOrEndString = isExample
      ? expiredString
      : isLead
      ? applicationDeadlineString
      : listingEndDateString;

    const createdAtString = intl.formatMessage(
      { id: 'ListingPage.createdAt' },
      {
        dates: `${formattedCreatedAt}`,
      }
    );

    const notAnExpertMessage = intl.formatMessage({ id: 'ListingPage.notAnExpertMessage' });

    const cannotEditListingMessage = intl.formatMessage({
      id: 'ListingPage.cannotEditListingMessage',
    });

    const saveTenderText = intl.formatMessage({ id: 'EditListingWizard.saveEditTender' });

    const publishListingSuccessMessage = intl.formatMessage({
      id: 'ListingPage.publishListingSuccessMessage',
    });

    const noManufacturerInfo = intl.formatMessage({
      id: 'ProfileManufacturerPage.infoNoManufacturer',
    });

    const exampleInfoMessage = intl.formatMessage({ id: 'ListingPage.exampleInfoMessage' });

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {!isExample && !isManufacturer && isLead && !isOwnListing ? (
              <div className={css.noManufacturerInfo}>{noManufacturerInfo}</div>
            ) : (
              <div>
                <SectionImages
                  title={title}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  editParams={{
                    id: listingId.uuid,
                    slug: listingSlug,
                    type: listingType,
                    tab: listingTab,
                  }}
                  imageCarouselOpen={this.state.imageCarouselOpen}
                  onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                  handleViewPhotosClick={handleViewPhotosClick}
                  onManageDisableScrolling={onManageDisableScrolling}
                  canEditListing={canEditListing}
                />
                <div className={css.contentContainer}>
                  <GenericMessage show={redirectToListing} message={publishListingSuccessMessage} />
                  <GenericMessage
                    show={this.state.showNotExpertMessage}
                    message={notAnExpertMessage}
                  />
                  <GenericMessage
                    show={isOwnListing && !canEditListing}
                    message={cannotEditListingMessage}
                  />
                  <div className={css.mainContent}>
                    <SectionHeading
                      createdAt={createdAtString}
                      applicationDeadline={deadlineOrEndString}
                      jobTypeGroup={jobTypeGroup}
                      onEditTender={this.onEditTender}
                      onEditTenderClick={() => this.setState({ editTenderModalOpen: true })}
                      isOwnListing={isOwnListing}
                      richTitle={richTitle}
                      category={category}
                      hostLink={hostLink}
                      showContactUser={showContactUser}
                      onContactUser={this.onContactUser}
                      isLead={isLead}
                      isExample={isExample}
                    />
                    <SectionDescriptionMaybe
                      intl={intl}
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      listingId={listingId}
                      description={description}
                      canEditListing={canEditListing}
                      isLead={isLead}
                      isExample={isExample}
                    />
                    <div className={css.featuresTitle}>{skillTooltipIdString}</div>
                    <SectionIndoorMaybe
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      listingId={listingId}
                      options={indoorOptions}
                      publicData={publicData}
                      canEditListing={canEditListing}
                      isLead={isLead}
                      isExample={isExample}
                    />
                    <SectionOutdoorMaybe
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      listingId={listingId}
                      options={outdoorOptions}
                      publicData={publicData}
                      canEditListing={canEditListing}
                      isLead={isLead}
                      isExample={isExample}
                    />
                    <SectionFormOfSupportMaybe
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      listingId={listingId}
                      options={formOfSupportOptions}
                      publicData={publicData}
                      canEditListing={canEditListing}
                      isLead={isLead}
                      isExample={isExample}
                    />
                    <SectionProjectDetailsMaybe
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      listingId={listingId}
                      publicData={publicData}
                      canEditListing={canEditListing}
                      isLead={isLead}
                      intl={intl}
                      formattedRealisationEndDate={formattedRealisationEndDate}
                      isExample={isExample}
                    />
                    <SectionWayOfSupportMaybe
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      listingId={listingId}
                      options={wayOfSupportOptions}
                      publicData={publicData}
                      canEditListing={canEditListing}
                      isLead={isLead}
                      isExample={isExample}
                    />
                    <SectionDialuxMaybe
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      listingId={listingId}
                      options={dialuxOptions}
                      publicData={publicData}
                      canEditListing={canEditListing}
                      isLead={isLead}
                      isExample={isExample}
                    />
                    <SectionLightingDesignMaybe
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      listingId={listingId}
                      options={lightingDesignOptions}
                      publicData={publicData}
                      canEditListing={canEditListing}
                      isLead={isLead}
                      isExample={isExample}
                    />
                    <SectionPhotoWorkMaybe
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      listingId={listingId}
                      options={photoWorkOptions}
                      publicData={publicData}
                      canEditListing={canEditListing}
                      isLead={isLead}
                      isExample={isExample}
                    />
                    <SectionDocumentationMaybe
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      listingId={listingId}
                      options={documentationOptions}
                      publicData={publicData}
                      canEditListing={canEditListing}
                      isLead={isLead}
                      isExample={isExample}
                    />
                    <SectionOthersMaybe
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      listingId={listingId}
                      options={othersOptions}
                      publicData={publicData}
                      canEditListing={canEditListing}
                      isLead={isLead}
                      isExample={isExample}
                    />
                    <SectionLanguageMaybe
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      listingId={listingId}
                      options={languageOptions}
                      publicData={publicData}
                      canEditListing={canEditListing}
                      isLead={isLead}
                      isExample={isExample}
                    />
                    <SectionMapMaybe
                      geolocation={geolocation}
                      publicData={publicData}
                      listingId={currentListing.id}
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      isLead={isLead}
                      isExample={isExample}
                      intl={intl}
                    />
                    {!isCurrentlyClosed && !isLead ? (
                      <SectionQ_A
                        title={title}
                        listing={currentListing}
                        currentUser={currentUser}
                        isOwnListing={isOwnListing}
                        unitType={unitType}
                        onSubmit={handleBookingSubmit}
                        title3={bookingTitle}
                        title2={breakdownTitle}
                        subTitle={bookingSubTitle}
                        authorDisplayName={authorDisplayName}
                        onManageDisableScrolling={onManageDisableScrolling}
                        onAddQuestion={() => {
                          this.setState({ questionModalOpen: true });
                        }}
                        isQuestionModalOpen={isAuthenticated && this.state.questionModalOpen}
                        onCloseQuestionModal={() => this.setState({ questionModalOpen: false })}
                        onSubmitQuestion={this.handleSendQuestion}
                        sendQuestionInProgress={sendQuestionInProgress}
                        sendQuestionError={sendQuestionError}
                        onAnswer={(q) => this.setState({ answerModalOpen: true, answerTo: q })}
                        isAnswerModalOpen={isAuthenticated && this.state.answerModalOpen}
                        onCloseAnswerModal={() => this.setState({ answerModalOpen: false })}
                        onSubmitAnswer={this.handleSendAnswer}
                        sendAnswerInProgress={sendAnswerInProgress}
                        sendAnswerError={sendAnswerError}
                        questions={questions}
                        users={users}
                        answerTo={this.state.answerTo}
                        onSeeAllQuestions={this.handleSeeAllQuestions}
                        allQuestions={this.state.allQuestions}
                        totalQuestionsCount={totalQuestionsCount}
                        setQuestionsRef={this.setQuestionsRef}
                        isExample={isExample}
                      />
                    ) : null}
                    {!isLead ? (
                      <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
                    ) : null}
                    <SectionClientMaybe
                      isOwnListing={isOwnListing}
                      listingType={listingType}
                      listingSlug={listingSlug}
                      listingId={listingId}
                      publicData={publicData}
                      canEditListing={canEditListing}
                      isLead={isLead}
                      intl={intl}
                      clientListingsCount={clientListingsCount}
                      LTZ={LTZ}
                      currentAuthor={ensuredAuthor}
                      postalCode={postalCode}
                      isExample={isExample}
                    />
                  </div>
                  <BookingPanel
                    className={css.bookingPanel}
                    isAuthenticated={isAuthenticated}
                    listing={currentListing}
                    currentUser={currentUser}
                    isOwnListing={isOwnListing}
                    unitType={unitType}
                    onSubmit={handleBookingSubmit}
                    title={bookingTitle}
                    title2={breakdownTitle}
                    subTitle={bookingSubTitle}
                    authorDisplayName={authorDisplayName}
                    onManageDisableScrolling={onManageDisableScrolling}
                    timeSlots={timeSlots}
                    fetchTimeSlotsError={fetchTimeSlotsError}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    applicationDeadline={applicationDeadlineString}
                    sendProposalInProgress={sendProposalInProgress}
                    sendProposalError={sendProposalError}
                    sendProposalMessage={sendProposalMessage}
                    onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
                    onOpenDataSecurityPolicy={() => this.setState({ dseModalOpen: true })}
                    onOpenLeadService={() => this.setState({ leadModalOpen: true })}
                    isLead={isLead}
                    isExample={isExample}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                  />
                </div>
              </div>
            )}
            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
                heading={<FormattedMessage id="ListingPage.enquiryFormHeading" />}
                //tip={<FormattedMessage id="ListingPage.enquiryFormTip" />}
                informationText={<FormattedMessage id="ListingPage.enquiryFormInformationText" />}
                canUploadAttachments={true}
                onFileUploadHandler={this.onFileUploadHandler}
                onFileRemove={this.onFileRemove}
                uploadInProgress={this.state.uploadInProgress}
                documents={this.state.documents}
              />
            </Modal>
            <Modal
              id="AuthenticationPage.tos"
              isOpen={this.state.tosModalOpen}
              onClose={() => this.setState({ tosModalOpen: false })}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.termsWrapper}>
                <h2 className={css.termsHeading}>
                  <FormattedMessage id="AuthenticationPage.termsHeading" />
                </h2>
                <TermsOfService />
              </div>
            </Modal>
            <Modal
              id="PrivacyPolicyPage.tos"
              isOpen={this.state.dseModalOpen}
              onClose={() => this.setState({ dseModalOpen: false })}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.termsWrapper}>
                <h2 className={css.termsHeading}>
                  <FormattedMessage id="DataPolicyPage.termsHeading" />
                </h2>
                <PrivacyPolicy />
              </div>
            </Modal>
            <Modal
              id="PrivacyPolicyPage.lead"
              isOpen={this.state.leadModalOpen}
              onClose={() => this.setState({ leadModalOpen: false })}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.termsWrapper}>
                <h2 className={css.termsHeading}>
                  <FormattedMessage id="TermsOfServiceLeadPage.heading" />
                </h2>
                <TermsOfServiceLead />
              </div>
            </Modal>
            <Modal
              id="ListingPage.editTender"
              contentClassName={css.editTenderModalContent}
              isOpen={this.state.editTenderModalOpen}
              onClose={() => this.setState({ editTenderModalOpen: false })}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EditApplicationDeadlineForm
                className={css.editDeadlineForm}
                initialValues={{
                  applicationDeadlineChanged: { date: applicationDeadlineChanged },
                  listingEndDate: { date: listingEndDate },
                  jobTypeGroup,
                  isLead,
                }}
                saveActionMsg={saveTenderText}
                onSubmit={(values) => {
                  this.onEditTender(values);
                }}
                updated={listingUpdated}
                updateInProgress={updateListingInProgress}
                fetchErrors={updateListingError}
                ready={false}
              />
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters
    .concat(config.manufacturerConfig.productPortfolio)
    .concat(config.manufacturerConfig.servicePortfolio)
    .concat(config.manufacturerConfig.countries)
    .concat(config.manufacturerConfig.dateFilters),
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;
  const {
    isExample,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    sendProposalInProgress,
    sendProposalError,
    sendProposalMessage,
    sendQuestionInProgress,
    sendQuestionError,
    sendAnswerInProgress,
    sendAnswerError,
    questions,
    totalQuestionsCount,
    updateListingInProgress,
    updateListingError,
    listingUpdated,
    totalListingsCount,
    postalCode,
  } = state.ListingPage;
  const { redirectToListing } = state.EditListingPage;

  const { canEditListing } = state.EditListingPage;

  const { currentUser } = state.user;

  const getListing = (id) => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = (id) => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 && !isExample ? listings[0] : null;
  };

  const users = () => {
    if (questions && questions.length > 0) {
      const usersRefs = questions.map((q) => {
        return {
          id: new UUID(q.user_id),
          type: 'user',
        };
      });
      return getMarketplaceEntities(state, usersRefs);
    } else {
      return [];
    }
  };
  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    sendProposalInProgress,
    sendProposalError,
    sendProposalMessage,
    sendQuestionInProgress,
    sendQuestionError,
    sendAnswerInProgress,
    sendAnswerError,
    questions,
    totalQuestionsCount,
    users: users(),
    canEditListing,
    updateListingInProgress,
    updateListingError,
    listingUpdated,
    redirectToListing,
    totalListingsCount,
    postalCode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message, metadata) =>
    dispatch(sendEnquiry(listingId, message, metadata)),
  onSendQuestion: (listingId, userId, authorId, question, all) =>
    dispatch(sendQuestion(listingId, userId, authorId, question, all)),
  onSendAnswer: (listingId, authorId, answer, all) =>
    dispatch(sendAnswer(listingId, authorId, answer, all)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onSendProposal: (values) => dispatch(sendProposal(values)),
  onSeeAllQuestions: (listingId, all) => dispatch(fetchQuestions({ listingId, all: all })),
  onCanEditListing: (id) => dispatch(canUserEditListing({ listingId: id })),
  editTender: (values) => dispatch(editTender(values)),
  onFetchPostalCode: (values) => dispatch(fetchPostalCode(values)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
