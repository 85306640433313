import React from 'react';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import NamedLink from '../../components/NamedLink/NamedLink';
import { useTranslation } from '../../hooks';
import { FormattedMessage } from '../../util/reactIntl';
import { TopbarContainer } from '../index';
import css from './ListingPage.module.css';

export function BronzeErrorPage() {
  const setTranslation = useTranslation('ListingPage');
  const pageTitle = setTranslation('errorLoadingListingTitle');

  const subscriptionDeeplink =
    <NamedLink name='SubscriptionPage'>
      <FormattedMessage id='ListingPage.errorBronzeUserLinkTitle'/>
    </NamedLink>;

  const schemaTitle = pageTitle || setTranslation('schemaTitle');
  const schemaDescription = pageTitle;
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    description: { schemaDescription },
    name: { schemaTitle },
  };

  return (

    <Page
      title={schemaTitle}
      scrollingDisabled={false}
      schema={schema}
      description={schemaDescription}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage='ListingPage'/>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <p className={css.noPrivilegesNotice}>
            <FormattedMessage
              id='ListingPage.errorBronzeUserMessage' values={{ subscriptionDeeplink }}
            />
          </p>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}
