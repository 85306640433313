import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { UserCard, Modal } from '../../components';
import { QuestionForm, AnswerForm } from '../../forms';
import { NamedLink, InlineTextButton, QA_Card, Avatar } from '../../components';
import EditIconDark from './EditIconDark';

import css from './ListingPage.module.css';

const SectionQ_A = props => {
  const {
    title,
    listing,
    authorDisplayName,
    currentUser,
    onManageDisableScrolling,
    onAddQuestion,
    isQuestionModalOpen,
    onCloseQuestionModal,
    onSubmitQuestion,
    sendQuestionInProgress,
    sendQuestionError,
    onAnswer,
    isAnswerModalOpen,
    onCloseAnswerModal,
    onSubmitAnswer,
    sendAnswerInProgress,
    sendAnswerError,
    questions,
    users,
    answerTo,
    onSeeAllQuestions,
    allQuestions,
    totalQuestionsCount,
    setQuestionsRef,
    isExample,
  } = props;

 if (!listing || !listing.author || !currentUser) {
    return null;
  }

  const showAllQuestions = allQuestions ? false : true;

  const askQuestion = (
    <InlineTextButton
      className={css.askQuestionButton}
      rootClassName={css.askQuestionButton}
      onClick={onAddQuestion}
      enforcePagePreloadFor="SignupPage"
    >
      <FormattedMessage id="ListingPage.askQuestion" />
    </InlineTextButton>
  );

  const seeAllQuestion = (
    <InlineTextButton
      className={css.seeAllQuestionButton}
      rootClassName={css.seeAllQuestionButton}
      onClick={() => onSeeAllQuestions(showAllQuestions)}
    >
      {
        showAllQuestions ?
        <FormattedMessage id="ListingPage.seeAllQuestion" values={{ questionsCount: totalQuestionsCount }}/> :
        <FormattedMessage id="ListingPage.seeLessQuestions" />
      }
    </InlineTextButton>
  );

  const user = answerTo && users.filter(u => u.id.uuid === answerTo.user_id)[0];
  const userDisplayName = user && user.attributes && user.attributes.profile.displayName;
  const question = answerTo && answerTo.question;

  const author = listing.author;

  return (
    <div id="host" className={css.sectionHost} ref={ref => setQuestionsRef(ref)}>
      <h2 className={css.yourHostHeading}>
        <FormattedMessage id="ListingPage.q_aHeading" />
      </h2>
      {
        questions.map(q =>
          <QA_Card
            q={q}
            key={`QaCard.${q.id}`}
            currentUser={currentUser}
            onAnswer={onAnswer}
            user1={users.filter(u => u.id.uuid === q.user_id)[0]}
            user2={author}
          />
        )
      }

      <div>
      {!isExample && currentUser && currentUser.id.uuid !== author.id.uuid ? askQuestion : null}
      {seeAllQuestion}
      </div>
      <Modal
        id="ListingPage.question"
        contentClassName={css.enquiryModalContent}
        isOpen={isQuestionModalOpen}
        onClose={onCloseQuestionModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <QuestionForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendQuestionError={sendQuestionError}
          onSubmit={onSubmitQuestion}
          inProgress={sendQuestionInProgress}
        />
      </Modal>

      <Modal
        id="ListingPage.answer"
        contentClassName={css.enquiryModalContent}
        isOpen={isAnswerModalOpen}
        onClose={onCloseAnswerModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <AnswerForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          userDisplayName={userDisplayName}
          sendAnswerError={sendAnswerError}
          onSubmit={onSubmitAnswer}
          sendAnswerInProgress={sendAnswerInProgress}
          question={question}
        />
      </Modal>
    </div>
  );
};

export default SectionQ_A;
