import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Map } from '../../components';
import config from '../../config';
import { NamedLink } from '../../components';
import EditIconDark from './EditIconDark';

import css from './ListingPage.module.css';

class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true };
  }
    render() {
    const { className, rootClassName, geolocation, publicData, listingId, listingSlug, listingType, isOwnListing, isLead, intl, isExample } = this.props;

    if (!geolocation) {
      return null;
    }

    const address = publicData && publicData.location ? publicData.location.address : '';
    const classes = classNames(rootClassName || css.sectionMap, className);
    const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;
    const editLinkName = isLead ? 'EditLeadPage' : 'EditListingPage';
    const tabName = isLead ? 'locations' : 'location';
    const editLink = !isExample && isOwnListing ? (<NamedLink className={css.editListingLinkDark} name={editLinkName} params={{
        id: listingId.uuid,
        slug: listingSlug,
        type: listingType,
        tab: tabName,
      }}>
      <EditIconDark className={css.editIcon} />
    </NamedLink>) : null;

    const isFuzzy = config.maps.fuzzy.enabled && isLead ? true : false;
    const mapProps = isFuzzy
      ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey), isFuzzy: isFuzzy }
      : { address, center: geolocation, isFuzzy: isFuzzy };
    const map = <Map {...mapProps} useStaticMap={this.state.isStatic} />;

    const postalcode = publicData && publicData.postcode ? publicData.postcode.slice(0,2) : null;
    const postalcodeString = publicData && publicData.postcode ? (intl.formatMessage(
      { id: 'ListingPage.postalCode' },
      {
        postalcode: `${postalcode}XXX`,
      }
    )) : null;

    return (
      <div className={classes}>
        <h2 className={css.locationTitle}>
          <FormattedMessage id="ListingPage.locationTitle" />
          {editLink}
        </h2>
        <div className={css.postalcode}>{postalcodeString}</div>

        {this.state.isStatic ? (
          <button
            className={css.map}
            onClick={() => {
              this.setState({ isStatic: false });
            }}
          >
            {map}
          </button>
        ) : (
          <div className={css.map}>{map}</div>
        )}
      </div>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default SectionMapMaybe;
