import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import EditIconDark from './EditIconDark';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const {
    createdAt,
    applicationDeadline,
    richTitle,
    category,
    hostLink,
    showContactUser,
    onContactUser,
    listingId,
    listingSlug,
    listingType,
    isOwnListing,
    onEditTender,
    onEditTenderClick,
    jobTypeGroup,
    isLead,
    isExample
  } = props;

  const editIcon = !isExample && isOwnListing ? <span onClick={onEditTenderClick} className={css.editListingLinkDark}><EditIconDark className={css.editIcon} /></span> : null;
  const jobTypeGroupTranslationKey = jobTypeGroup === 'private' ? 'ListingPage.private' : 'ListingPage.public';
  return isLead ? (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <div className={css.title}>{richTitle}</div>
        <div className={css.underTitle}>
            {createdAt}
        </div>
        <div className={css.tenderfields}>
          <span className={css.deadLine}>{applicationDeadline}</span>
          {editIcon}
        </div>
      </div>
    </div>
  ) :
  <div className={css.sectionHeading}>
    <div className={css.heading}>
      <div className={css.title}>{richTitle}</div>
      <div className={css.underTitle}>
          {createdAt}
      </div>
      <div className={css.tenderfields}>
        <span className={css.deadLine}>{applicationDeadline}</span>
        <span className={css.jobTypeGroup}>
          <span className={css.jobTypeGroupLabel}>
            <FormattedMessage id="ListingPage.jobTypeGroup"/>
          </span>
          <span className={css.jobTypeGroupValue}>
            <FormattedMessage id={jobTypeGroupTranslationKey}/>
          </span>
        </span>
        {editIcon}
      </div>
    </div>
  </div>;
};

export default SectionHeading;
