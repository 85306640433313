import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import { NamedLink } from '../../components';
import EditIconDark from './EditIconDark';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';

import css from './ListingPage.module.css';
const { Money } = sdkTypes;

const SectionProjectDetailsMaybe = props => {
  const { publicData, listingId, listingSlug, listingType, isOwnListing, canEditListing, isLead, intl, formattedRealisationEndDate, isExample } = props;
  if (!publicData) {
    return null;
  }

  const floorSpaceLabelId = publicData.measureType === 'dimensions' ? 'ListingPage.dimensions' : 'ListingPage.floorspace';
  const floorSpaceValue = publicData.floorSpace ?? '';
  const length = publicData.length ?? '';
  const width = publicData.width ?? '';
  const height = publicData.height ?? '';
  const heightUnit = publicData.heightUnit === 'meter' ? 'm' : 'ft';
  const floorAreaUnit = publicData.dimensionsUnit === 'meterSquare' ? 'm²' : 'ft²';
  const floorCount = publicData.floors ?? '';
  const luminaireCount = publicData.luminaires ?? '';
  const formatedBudget = publicData.budget ? formatMoney(intl, new Money(publicData.budget, config.currency)) : '';
  const heightString = `${height} ${heightUnit}`;
  const floorSpaceString = (publicData.measureType === 'space') ? (
    `${floorSpaceValue} ${floorAreaUnit}`
  ):(
    `${length} ${heightUnit} x ${width} ${heightUnit}`
  );

  if(isLead){
    const editLink = !isExample && isOwnListing && canEditListing ? (<NamedLink className={css.editListingLinkDark} name="EditLeadPage" params={{
        id: listingId.uuid,
        slug: listingSlug,
        type: listingType,
        tab: "details",
        accordion: "1",
        }}>
        <EditIconDark className={css.editIcon} />
      </NamedLink>
    ) : null;
    return (
        <div className={css.sectionFeatures}>
          <h2 className={css.featuresTitle}>
            <FormattedMessage id="ListingPage.projectDetailsTitle" />
            {editLink}
          </h2>
          <div className={css.equalColumnGrid}>
            <div><FormattedMessage id={`${floorSpaceLabelId}`} /></div>
            <div>{floorSpaceString}</div>
            <div><FormattedMessage id="ListingPage.height" /></div>
            <div>
              {height ? heightString : <FormattedMessage id="ListingPage.unspecifiedAttribute" />}
            </div>
            <div><FormattedMessage id="ListingPage.numberOfFloors" /></div>
            <div>
              {!!floorCount ? floorCount : <FormattedMessage id="ListingPage.unspecifiedAttribute" />}
            </div>
            <div><FormattedMessage id="ListingPage.numberOfLuminaries" /></div>
            <div>
              {!!luminaireCount ? luminaireCount : <FormattedMessage id="ListingPage.unspecifiedAttribute" />}
            </div>
            <div><FormattedMessage id="ListingPage.budget" /></div>
            <div>
              {!!publicData.budget ? formatedBudget : <FormattedMessage id="ListingPage.unspecifiedAttribute" />}
            </div>
            <div><FormattedMessage id="ListingPage.realisationTime" /></div>
            <div>{formattedRealisationEndDate}</div>
          </div>
        </div>
    );
  }
  return null;
};

export default SectionProjectDetailsMaybe;
