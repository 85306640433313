import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import { NamedLink } from '../../components';
import EditIconDark from './EditIconDark';

import css from './ListingPage.module.css';

const SectionLanguageMaybe = props => {
  const { options, publicData, listingId, listingSlug, listingType, isOwnListing, canEditListing, isLead, isExample } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions = publicData && publicData.language ? publicData.language : [];
  const editLinkName = isLead ? 'EditLeadPage' : 'EditListingPage';
  const tabName =  isLead ? 'languages' : 'language';
  if(selectedOptions.length>0){
    const editLink = !isExample && isOwnListing && canEditListing ? (<NamedLink className={css.editListingLinkDark} name={editLinkName} params={{
      id: listingId.uuid,
      slug: listingSlug,
      type: listingType,
      tab: tabName,
    }}>
    <EditIconDark className={css.editIcon} />
    </NamedLink>) : null;
    return (
      <div className={css.sectionFeatures}>
        <h2 className={css.featuresTitle}>
          <FormattedMessage id="ListingPage.languageTitle" />
          {editLink}
        </h2>
        <PropertyGroup
          id="ListingPage.amenities"
          options={options}
          selectedOptions={selectedOptions}
          twoColumns={true}
        />
        <div className={css.tooltip}>
          <FormattedMessage id="ListingPage.languageTooltip" />
        </div>
      </div>
    );
  }
  return null;
};

export default SectionLanguageMaybe;
