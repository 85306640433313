import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import { NamedLink } from '../../components';
import EditIconDark from './EditIconDark';

import css from './ListingPage.module.css';

const SectionPhotoWorkMaybe = props => {
  const { options, publicData, listingId, listingSlug, listingType, isOwnListing, canEditListing, isExample } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions = publicData && publicData.photoWork ? publicData.photoWork : [];

  if(selectedOptions.length>0){
    const editLink = !isExample && isOwnListing && canEditListing ? (<NamedLink className={css.editListingLinkDark} name="EditListingPage2" params={{
        id: listingId.uuid,
        slug: listingSlug,
        type: listingType,
        tab: "categories",
        accordion: "4"
        }}>
        <EditIconDark className={css.editIcon} />
      </NamedLink>
    ) : null;
    return (
      <div className={css.sectionFeatures}>
        <h2 className={css.featuresTitle}>
          <FormattedMessage id="ListingPage.photoWorkTitle" />
          {editLink}
        </h2>
        <PropertyGroup
          id="ListingPage.photoWork"
          options={options}
          selectedOptions={selectedOptions}
          twoColumns={true}
        />
      </div>
    );
  }
  return null;
};

export default SectionPhotoWorkMaybe;
