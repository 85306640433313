import moment from 'moment-timezone';
import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import EditIconDark from './EditIconDark';

import css from './ListingPage.module.css';

const SectionClientMaybe = (props) => {
  const {
    publicData,
    listingId,
    listingSlug,
    listingType,
    isOwnListing,
    canEditListing,
    isLead,
    intl,
    clientListingsCount,
    LTZ,
    currentAuthor,
    postalCode,
    isExample,
  } = props;

  const postCode = postalCode?.slice(0, 2) ?? '';
  if (!publicData) {
    return null;
  }

  const joinedAtformat = {
    month: 'short',
    year: 'numeric',
  };

  const clientJoinedAt = currentAuthor.attributes.createdAt
    ? currentAuthor.attributes.createdAt
    : null;
  const clientJoinedAtLTZ = moment.tz(clientJoinedAt, LTZ).format();
  const formattedclientJoinedAt = intl.formatDate(clientJoinedAtLTZ, joinedAtformat);
  const jobtitle = currentAuthor?.attributes?.profile?.publicData?.jobTitle || '';
  const country = currentAuthor?.attributes?.profile?.publicData?.country || null;
  const sectionJobtitleLabel =
    jobtitle !== null
      ? intl.formatMessage({
          id: 'ListingPage.jobTitleLabel',
          class: css.jobTitleLabel,
        })
      : null;
  const editLink =
    !isExample && isOwnListing && canEditListing ? (
      <NamedLink
        className={css.editListingLinkDark}
        name="EditListingPage2"
        params={{
          id: listingId.uuid,
          slug: listingSlug,
          type: listingType,
          tab: 'categories',
          accordion: '1',
        }}
      >
        <EditIconDark className={css.editIcon} />
      </NamedLink>
    ) : null;

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.leadClientTitle" />
        {editLink}
      </h2>
      <div className={css.equalColumnGrid}>
        {sectionJobtitleLabel}
        <div>{jobtitle}</div>
        <div>
          <FormattedMessage id="ListingPage.memberSinceLabel" />
        </div>
        <div>{formattedclientJoinedAt}</div>
        <div>
          <FormattedMessage id="ListingPage.locatedAtLabel" />
        </div>
        <div>{country}</div>
        {postCode && (
          <>
            <div>
              <FormattedMessage id="ListingPage.postalcodeLabel" />
            </div>
            <div className={css.postalcode}>{postCode}XXX</div>
          </>
        )}

        <div>
          <FormattedMessage id="ListingPage.totalTendersLabel" />
        </div>
        <div>{clientListingsCount}</div>
      </div>
    </div>
  );
};

export default SectionClientMaybe;
